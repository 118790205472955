import React from "react";
import classnames from "classnames";
import * as loaderStyles from "../Button/index.module.scss";
import * as styles from "./Footer.module.scss";
import _ from "lodash";
import {HOMEPAGE_TEMPLATE_KEY} from "../../utils/constants";
import {isNewsfeedTemplateLayout} from "../../logic/templates/isNewsfeedTemplateLayout";
import { isShowingBottomTabs } from "../../logic/templates/isShowingBottomTabs";
import { useSelector } from "react-redux";

const DEFAULT_HEIGHT = 84;

const Footer = ({
  children,
  background,
  center,
  transparentGradient,
  relativePosition,
  appStyles = {},
  classNames,
  hideOnDesktop,
  sidebarMode,
  ...props
}) => {
  const isMobile = useSelector(({ app: { isMobile } }) => isMobile);
  const isShowingTabs = isShowingBottomTabs(appStyles);
  const height =
    props.height ||
    DEFAULT_HEIGHT +
      (isShowingTabs && isMobile ? 78 : 0);

  const baseStyle = { background: background, height };
  return (
    <div>
      <div
        {...props}
        className={classnames(
          classNames,
          styles.Footer,
          hideOnDesktop && styles.HideOnDesktop,
          center && styles.Center,
          !transparentGradient &&
            (appStyles.gradientStyleTypeDark ? styles.Dark : styles.Light),
          sidebarMode && styles.SidebarMode,
          appStyles.rtl && styles.RTL,
        )}
        style={{
          ...baseStyle,
          ...(relativePosition && { position: "relative" }),
        }}
      >
        {children}
      </div>

      <div style={{ height: height + 8 }} />
    </div>
  );
};

Footer.Button = React.forwardRef(({
                                    children,
                                    left,
                                    right,
                                    spread,
                                    color,
                                    appStyles,
                                    loading,
                                    completed,
                                    disabled,
                                    onClick,
                                    sidebarMode,
                                    classNames,
                                    center,
                                    tall,
                                    style,
                                    ...props
                                  }, ref) => {
  const { Button = {}, ButtonDisabled = {} } = appStyles;
  const backgroundImage = _.get(Button, "backgroundImage");
  return (
      <a
          {...props}
          ref={ref}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (!loading && !completed) {
              onClick(e);
            }
          }}
          className={classnames(
              loaderStyles.Button,
              styles.Button,
              right && styles.Right,
              left && styles.Left,
              spread && !loading && !completed && styles.Spread,
              disabled && styles.Disabled,
              sidebarMode && styles.SidebarMode,
              center && styles.Center,
              tall && styles.Tall,
              classNames,
          )}
          style={{
            ...(disabled ? ButtonDisabled : Button),
            ...style,
            ...(backgroundImage && {backgroundImage: `url("${backgroundImage}")`, backgroundSize: "cover", backgroundRepeat:"round"})
          }}
          id={"text-button"}
          role="button"
      >
        {!loading && !completed ? children : null}
        {(loading || completed) && (
            <span
                className={classnames(
                    loaderStyles.CircleLoader,
                    completed && loaderStyles.LoadComplete,
                )}
            >
          {completed && (
              <span
                  className={classnames(loaderStyles.Checkmark, loaderStyles.Draw)}
              />
          )}
        </span>
        )}
      </a>
  );
});
Footer.Label = ({ children, left, right, error, ...props }) => (
  <div
    {...props}
    className={classnames(
      styles.Label,
      error && styles.Error,
      right && styles.Right,
      left && styles.Left,
    )}
  >
    {children}
  </div>
);

export default Footer;
