exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-contacts-js": () => import("./../../../src/templates/contacts.js" /* webpackChunkName: "component---src-templates-contacts-js" */),
  "component---src-templates-donate-js": () => import("./../../../src/templates/donate.js" /* webpackChunkName: "component---src-templates-donate-js" */),
  "component---src-templates-find-location-js": () => import("./../../../src/templates/find-location.js" /* webpackChunkName: "component---src-templates-find-location-js" */),
  "component---src-templates-gift-card-js": () => import("./../../../src/templates/gift-card.js" /* webpackChunkName: "component---src-templates-gift-card-js" */),
  "component---src-templates-gift-js": () => import("./../../../src/templates/gift.js" /* webpackChunkName: "component---src-templates-gift-js" */),
  "component---src-templates-gift-thanks-js": () => import("./../../../src/templates/gift-thanks.js" /* webpackChunkName: "component---src-templates-gift-thanks-js" */),
  "component---src-templates-gifts-js": () => import("./../../../src/templates/gifts.js" /* webpackChunkName: "component---src-templates-gifts-js" */),
  "component---src-templates-history-js": () => import("./../../../src/templates/history.js" /* webpackChunkName: "component---src-templates-history-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-login-js": () => import("./../../../src/templates/login.js" /* webpackChunkName: "component---src-templates-login-js" */),
  "component---src-templates-menu-item-js": () => import("./../../../src/templates/menuItem.js" /* webpackChunkName: "component---src-templates-menu-item-js" */),
  "component---src-templates-my-account-js": () => import("./../../../src/templates/my-account.js" /* webpackChunkName: "component---src-templates-my-account-js" */),
  "component---src-templates-order-js": () => import("./../../../src/templates/order.js" /* webpackChunkName: "component---src-templates-order-js" */),
  "component---src-templates-payment-js": () => import("./../../../src/templates/payment.js" /* webpackChunkName: "component---src-templates-payment-js" */),
  "component---src-templates-payment-methods-js": () => import("./../../../src/templates/payment-methods.js" /* webpackChunkName: "component---src-templates-payment-methods-js" */),
  "component---src-templates-prepaid-js": () => import("./../../../src/templates/prepaid.js" /* webpackChunkName: "component---src-templates-prepaid-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-profile-js": () => import("./../../../src/templates/profile.js" /* webpackChunkName: "component---src-templates-profile-js" */),
  "component---src-templates-purchase-event-js": () => import("./../../../src/templates/purchase-event.js" /* webpackChunkName: "component---src-templates-purchase-event-js" */),
  "component---src-templates-reorder-js": () => import("./../../../src/templates/reorder.js" /* webpackChunkName: "component---src-templates-reorder-js" */),
  "component---src-templates-scan-qr-js": () => import("./../../../src/templates/scan-qr.js" /* webpackChunkName: "component---src-templates-scan-qr-js" */),
  "component---src-templates-serving-options-js": () => import("./../../../src/templates/serving-options.js" /* webpackChunkName: "component---src-templates-serving-options-js" */),
  "component---src-templates-share-js": () => import("./../../../src/templates/share.js" /* webpackChunkName: "component---src-templates-share-js" */),
  "component---src-templates-signup-js": () => import("./../../../src/templates/signup.js" /* webpackChunkName: "component---src-templates-signup-js" */),
  "component---src-templates-signup-popup-embed-js": () => import("./../../../src/templates/signup-popup-embed.js" /* webpackChunkName: "component---src-templates-signup-popup-embed-js" */),
  "component---src-templates-subscriptions-js": () => import("./../../../src/templates/subscriptions.js" /* webpackChunkName: "component---src-templates-subscriptions-js" */),
  "component---src-templates-tos-js": () => import("./../../../src/templates/tos.js" /* webpackChunkName: "component---src-templates-tos-js" */),
  "component---src-templates-verify-code-js": () => import("./../../../src/templates/verifyCode.js" /* webpackChunkName: "component---src-templates-verify-code-js" */)
}

